<script setup>
import { Head, Link, useForm } from '@inertiajs/vue3';;
import AuthenticationCard from '@/Components/AuthenticationCard.vue';
import AuthenticationCardLogo from '@/Components/AuthenticationCardLogo.vue';
import Checkbox from '@/Components/Checkbox.vue';
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import TextInput from '@/Components/TextInput.vue';
import { CheckIcon, XMarkIcon } from '@heroicons/vue/16/solid';
import { ref } from 'vue';

defineProps({
    canResetPassword: Boolean,
    status: String,
});

const form = useForm({
    email: '',
    password: '',
    remember: false,
});

const two_factor_phone = false;
const otp = ref('');

// const requiresOtp = ref(false);

const submit = () => {
    form.transform(data => ({
        ...data,
        remember: form.remember ? 'on' : '',
    })).post(route('login'), {
        onSuccess: (response) => {
            form.reset('password');
        },
        onError: () => {
            form.reset('password');
        },
    });
};



const verifyOtp = async () => {
  try {
    const response = await axios.post("/api/2fa/sms/verify", {
      email: email.value,
      otp: otp.value,
    });

    localStorage.setItem("token", response.data.token);
    window.location.href = "/dashboard";
  } catch (error) {
    alert("Invalid OTP");
  }
};
</script>

<template>
    <Head title="Log in" />
    

    <AuthenticationCard>
        <template #logo>
            <AuthenticationCardLogo />
        </template>


        <div v-if="$page.props.flash.success" class="bg-green-500 text-white p-3  mb-4 rounded-md shadow-md">
        <div class="flex items-center">
        <div>
        <CheckIcon class="size-10 text-center" />
        </div>
        <div class="ms-2">
            {{ $page.props.flash.success }}
        </div>
        </div>
        </div>

        <div v-if="$page.props.flash.error" class="bg-red-400 text-white p-3  mb-4 rounded-md shadow-md">
        <div class="flex items-center">
        <div>
        </div>
        <div class="ms-2">
            {{ $page.props.flash.error }}
        </div>
        </div>
        </div>

        <div v-if="status" class="mb-4 font-medium text-sm text-green-600 dark:text-green-400">
            {{ status }}
        </div>

        <form v-if="!two_factor_phone" @submit.prevent="submit">
            <div>
                <InputLabel for="email" value="Email" />
                <TextInput
                    id="email"
                    v-model="form.email"
                    type="email"
                    class="mt-1 block w-full"
                    required
                    autofocus
                    autocomplete="username"
                />
                <InputError class="mt-2" :message="form.errors.email" />
            </div>

            <div class="mt-4">
                <InputLabel for="password" value="Password" />
                <TextInput
                    id="password"
                    v-model="form.password"
                    type="password"
                    class="mt-1 block w-full"
                    required
                    autocomplete="current-password"
                />
                <InputError class="mt-2" :message="form.errors.password" />
            </div>

            <div class="block mt-4">
                <label class="flex items-center">
                    <Checkbox v-model:checked="form.remember" name="remember" />
                    <span class="ms-2 text-sm text-gray-600 dark:text-gray-400">Remember me</span>
                </label>
            </div>

            <div class="flex items-center justify-end mt-4">
                <Link v-if="canResetPassword" :href="route('password.request')" class="underline text-sm text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 dark:focus:ring-offset-gray-800">
                    Forgot your password?
                </Link>

                <PrimaryButton class="ms-4" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                    Log in
                </PrimaryButton>
            </div>
        </form>

    </AuthenticationCard>
</template>
